// core
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import CommonPageBanner from '../components/CommonPageBanner'


const TermsAndConditionsPage = ({data}) => {

  const pageData = data.prismicGeneralDocumentsPage.data

  return (
      <Layout currentPage="terms-and-conditions">
        <Seo
            title={pageData.meta_title  && pageData.meta_title.text}
            description={pageData.meta_description && pageData.meta_description.text}
        />
          <CommonPageBanner
              mobileBackgroundUrl={pageData.banner_background__mobile && pageData.banner_background__mobile.url}
              backgroundUrl={pageData.banner_background && pageData.banner_background.url}
          />
          <div className="container py-5">
            {parse(pageData.page_content.text)}
          </div>
      </Layout>
  );
}

export const query = graphql`
query TermsAndConditionsPageQuery{
    prismicGeneralDocumentsPage (uid: {eq: "terms-and-conditions"}) {
        _previewable
        data {
            banner_background {
                alt
                url
            }
            banner_background__mobile {
                alt
                copyright
                url
            }

            meta_description {
                text
            }
            meta_title {
                text
            }
            page_content {
                html
                text
            }
        }
    }
}
`
export default withPrismicPreview(TermsAndConditionsPage)